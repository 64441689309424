<template>
    <div data-scroll :data-scroll-id="name" class="reputation-layout-2">
        <div class="header-container">
            <text-wipe id="reputation-header" :header="header" />
        </div>
        <div v-observer class="images-container">
            <image-scale
                v-observer
                v-for="(item, index) in images"
                :item="item"
                :key="index"
                :index="index"
            />
            <div class="cover-image">
                <img
                    :src="coverImage"
                    data-scroll
                    data-scroll-id="inside-scale"
                    class="image"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageScale from "@/components/custom/ImageScale";
import textWipe from "@/components/custom/textWipe";
import first from "lodash/first";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        ImageScale,
        textWipe
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        mainItem() {
            return first(this.data?.list);
        },
        coverImage() {
            return this?.mainItem?.cover_image?.[0]?.devices[this.device?.type];
        },
        images() {
            return this.mainItem?.images
                ?.map(item => ({
                    image: item?.devices?.[this.device?.type],
                    description: item?.title?.description,
                    title: item?.title?.title,
                    teaser: item?.title?.teaser
                }))
                ?.slice(0, 3);
        },
        header() {
            return this.config.header || {};
        }
    },
    methods: {
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(
                    ".reputation-layout-2 .image-speed"
                );
                new this.simpleParallax(images[0], {
                    customContainer: document.querySelector("body"),
                    overflow: true,
                    orientation: "up"
                });
                new this.simpleParallax(images[1], {
                    customContainer: document.querySelector("body"),
                    overflow: true,
                    delay: 0.6,
                    transition: "cubic-bezier(0,0,0,1)",
                    orientation: "up"
                });
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.reputation-layout-2 /deep/ {
    background: #0a141c;

    .text-wipe {
        padding: 144px 365px 89px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 144px 355px 89px;
            .header {
                line-height: 100px;
            }
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 144px 318px 89px;
            .header {
                line-height: 100px;
            }
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            padding: 89px 280px;
            .header {
                line-height: 60px;
            }
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 89px 180px;
            .header {
                line-height: 60px;
            }
        }
        @media only screen and (max-width: 767px) {
            padding: 55px 66px 0;
            .header {
                line-height: 40px;
            }
        }

        .header {
            text-align: center;
        }
    }

    .images-container {
        position: relative;
        margin-top: 204px;

        .image-speed {
            position: absolute;
            z-index: 1;

            .image-container {
                width: fit-content;
                height: 100%;
                overflow: hidden;
                transition: all 0.6s 0.1s $ease-out;
            }

            &.observed {
                .image-container {
                    clip-path: inset(30px) !important;
                    @media only screen and (max-width: 1024px) {
                        clip-path: inset(15px) !important;
                    }
                }
            }

            &:nth-of-type(1) {
                left: 195px;
                top: -200px;
                width: 479px;

                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    left: 135px;
                    width: 410px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    left: 98px;
                    width: 410px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    left: 17px;
                    width: 340px;
                    top: -150px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    left: 17px;
                    width: 244px;
                    top: -100px;
                }
                @media only screen and (max-width: 767px) {
                    width: 210px;
                    top: -100px;
                    left: 1px;
                }

                .image-container {
                    clip-path: inset(30px 30px 60% 30px);
                }
            }

            &:nth-of-type(2) {
                right: 150px;
                top: -100px;
                width: 405px;

                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    right: 135px;
                    width: 315px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    right: 98px;
                    width: 315px;
                    top: -70px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    right: 17px;
                    width: 270px;
                    top: -70px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 192px;
                    top: -50px;
                    right: 17px;
                }
                @media only screen and (max-width: 768px) {
                    width: 195px;
                    top: -50px;
                    right: -15px;
                }

                .image-container {
                    clip-path: inset(30px 30px 80% 30px);
                }
            }

            &:nth-of-type(3) {
                right: 30%;
                bottom: 10vh;
                width: 630px;
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    width: 410px;
                    bottom: 54px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 350px;
                    bottom: 54px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 245px;
                    bottom: 30px;
                    right: 20%;
                }
                @media only screen and (max-width: 767px) {
                    width: 220px;
                    right: 1px;
                    bottom: 21px;
                }

                .image-container {
                    clip-path: inset(30px 30px 70% 30px);
                }
            }
        }

        .cover-image {
            width: 100%;
            height: 718px;
            background: #0a141c;
            overflow: hidden;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                height: 458px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1365px) {
                height: 434px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1023px) {
                height: 325px;
            }
            @media only screen and (max-width: 1023px) {
                height: 244px;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.2;
            }
        }
    }
}
</style>
